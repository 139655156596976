

.header {
  width: 100%;
  min-height: 450px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  /* padding: 150px 200px; */
  position: relative;
}



.image {
  width: 100%;
  height: 450px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  padding: 150px 136px;
  z-index: 10;
}

.mask {
  width: 100%;
  height: 450px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  background: rgba(11, 60, 199, 0.3);
}


.title1 {
  font-weight: 700;
  font-size: 64px;
  color: #000000;
  margin-bottom: 30px;
}
.title2 {
  font-size: 24px;
  line-height: 30px;
  color: #7A7A7A;
}

.header1 .title1 {
  color: #FFFFFF;
}
.header1 .title2 {
  color: #F9F9F9;
}


@media screen and (max-width: 750px) {
  .header {
    width: 100%;
    height: 403px;
    padding: 100px 48px;
  }

  .image {
    width: 100%;
    height: 403px;
    padding: 100px 48px;
  }

  .mask {
    height: 403px;
  }

  .title1 {
    font-weight: 700;
    font-size: 48px;
    line-height: 60px;
    color: #000000;
    margin-bottom: 17px;
  }
  .title2 {
    font-weight: 400;
    font-size: 32px;
    line-height: 50px;
    color: #888888;
    width: 100%;
    white-space: pre-wrap;
  }
}

