
/* .container {
  position: relative;
} */

.container {
  /* width: 100%; */

}
.more {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px !important;
  -moz-user-select:none; /*火狐*/
  -webkit-user-select:none; /*webkit浏览器*/
  -ms-user-select:none; /*IE10*/
  -khtml-user-select:none; /*早期浏览器*/
  user-select:none;
}

.moreIcon {
  position: absolute;
  bottom: 20px;
  display: flex;
  align-items: center;
  gap: 15px

}
.moreIcon span {
  color: white;
  font-size: 16px;
  font-weight: 500;
}

.splide {
  position: relative;
}

.splideContent {
  position: absolute;
  bottom: 150px;
  width: 100vw;
  animation: decline 1s ease 0s 1 normal forwards;
}

.splide h1 {
 color: white;
 margin: 0px;
}
.mask {
  width: 100%;
  height: 100%;
  background: #2c60f505;
  position: absolute;
  border-radius: 50%;
}

.year {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
  cursor: pointer;
  color: white;
  font-size: 40px;
  font-weight: 900;
}

.year .label {
  font-family: Source Han Sans SC;
  font-size: 24px;
  font-weight: 700;
  line-height: 35px;
  letter-spacing: -0.06em;
  text-align: center;
  color: white;
  cursor: pointer;
  margin: 0px 5px;
}

.event  {
  height: 132px;
  width: 132px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: #ffffffe0 5px solid;
  background-size: 200px;
  position: relative;
}

.background {
  width: 100vw;
  height: calc(100vh - 86px);
  overflow: hidden;
  background-position: bottom;
  background-repeat: no-repeat;
  position: absolute;
  bottom: 0px;
}

.bg1 {
  background-image: url("./img/bg-1.png");
}
.bg2 {
  background-image: url("./img/bg-2.png");
  bottom: 50px;
  animation: decline 1s ease 0s 1 normal forwards;
}
.bg3 {
  background-image: url("./img/bg-3.png");
}

.text {
  color: white;
  font-size: 247px;
  font-weight: 900;
  letter-spacing: -0.06em;
  text-align: center;
  position: absolute;
  padding-top: 150px;
  bottom: 330px;
  width: 100vw;
  background-image: linear-gradient(to bottom, white,  #ffffff70);
  -webkit-background-clip: text;
  color: transparent;
  animation: decline 1s ease 0s 1 normal forwards;
}

@keyframes decline {
  0% {
		opacity: 0;
		transform: translateY(50px);
	}
	100% {
		opacity: 1;
		transform: translateY(0);
	}
}

@media screen and (max-width: 1280px) {

  .text {
    font-size: 130px;
    animation: decline1 1s ease 0s 1 normal forwards;
  }

  @keyframes decline1 {
    0% {
      opacity: 0;
      transform: translateY(50px);
    }
    100% {
      opacity: 1;
      transform: translateY(-30px);
    }
  }

}

@media screen and (max-width: 750px) {
  .background {
    /* background-position-x: -20rem; */
  }

  .text {
    font-family: Source Han Sans SC;
    font-size: 149px;
    font-weight: 900;
    line-height: 216px;
    letter-spacing: -0.06em;
    text-align: center;
    bottom: 750px;
    width: 100vw;
    background-image: linear-gradient(to bottom, white,  #ffffff70);
    -webkit-background-clip: text;
    color: transparent;
    animation: decline 1s ease 0s 1 normal forwards;
  }

  .bg2 {
    background-image: url("./img/bg-2.png");
    bottom: 20px;
  }

  .more {
    display: none;
  }

  .splideContent {
    position: absolute;
    bottom: 300px;
    width: 100vw;
    animation: decline 1s ease 0s 1 normal forwards;
  }

  .year {
    font-size: 30px;
    font-weight: 500;
  }
}