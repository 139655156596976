

.card {
  padding: 90px 135px;
  display: flex;
  align-items: center;
  gap: 50px;
  justify-content: space-between;
}

.image {
  width: 560px;
  flex: 0 0 560px;
  height: 314px;
  object-fit: cover;
  border: 1px solid #D6D6D6;
}
.name {
  font-weight: 700;
  font-size: 55px;
  line-height: 60px;
  color: #000000;
  margin-bottom: 25px;
}
.text {
  font-weight: 400;
  font-size: 22px;
  color: #7A7A7A;
  margin-bottom: 25px;
  white-space: pre-wrap;
}
.link {
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
  color: #2C60F5;
  display: flex;
  gap: 5px;
  align-items: center;
  cursor: pointer;
}
.icon {
  width: 9px;
  height: 15px;
}


@media screen and (max-width: 750px) {


  .card {
    padding: 58px 48px;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 33px;
  }
  
  .image {
    width: 654px;
    flex: 0 0 367px;
    height: 367px;
    object-fit: cover;
    border: 1px solid #D6D6D6;
    border-radius: 30px;
  }
  .name {
    font-weight: 700;
    font-size: 48px;
    line-height: 60px;
    color: #000000;
    margin-bottom: 33px;
  }
  .text {
    font-weight: 400;
    font-size: 32px;
    line-height: 150%;
    color: #333333;
    margin-bottom: 33px;
  }
  .link {
    font-weight: 500;
    font-size: 26px;
    line-height: 45px;
    color: #2C60F5;
    display: flex;
    gap: 5px;
    align-items: center;
    cursor: pointer;
  }
  .icon {
    width: 13px;
    height: 23px;
  }
}