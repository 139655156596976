
.secondNav {
  position: fixed;
  bottom: 50px;
  right: 50px;
  zoom: 0.7;
  z-index: 100;
}

@media screen and (max-width: 750px) {
  .secondNav {
    display: none;
  }
}

.secondNav .button {
  width: 80px;
  height: 80px;
  border-radius: 7px;
  border: 1px solid #00000033;
  background-color: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 6px;
  flex-direction: column;
  cursor: pointer;
  user-select:none;
}

.button:hover {
  background: #2C60F5;
}

.button path {
  opacity: 0.5;
}

.button:hover  path {
  fill: white;
  opacity: 1;
}
.button:hover  span {
  color: white;
  opacity: 1;
}

.button span {
  opacity: 0.5;
  margin-top: 4px;
  font-size: 15px;
  color: #000000;
}

.connectionIcon {
  width: 30px;
  height: 30px;
}

.topIcon {
  width: 40;
  height: 50px;
}

.subscribeBox {
  width: 183px;
  height: 183px;
  background:#2C60F5;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 18px;
  padding: 20px 26px 6px 26px;
  position: absolute;
  bottom: 0px;
  right: 84px;
}

.subscribeBox img {
  width: 130px;
  height: 130px;
}


.subscribeBox .text {
  display: flex;
  margin-top: 4px;
  font-weight: 500;
  gap: 6px;
}
