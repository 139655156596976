

.card2 {
  padding: 140px 134px 113px 133px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.label {
  font-weight: 700;
  font-size: 64px;
  line-height: 60px;
  margin-bottom: 40px;
}

.desc {
  font-weight: 400;
  font-size: 24px;
  line-height: 150%;
  color: #7A7A7A;
  margin-bottom: 78px;
  display: flex;
  justify-content: center;
  white-space: pre-wrap;
  direction: ltr;
}

.desc span {
  /* max-width: 700px; */
  text-align: center;
  font-size: 22px;
}

.card1 {
  padding: 122px 0 130px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.image1 {
  width: 949px;
  height: 533px;
  object-fit: contain;
  border: 1px solid #D6D6D6;
}


.card {
  display: flex;
  align-items: center;
  gap: 50px;
  margin-bottom: 96px;
  justify-content: space-between;
  width: 100%;
}

.card:last-of-type {
  margin-bottom: 0px;
}

.left {
  direction: ltr;
}
.right {
  width: 560px;
  min-width: 560px;
  max-width: 560;
  object-fit: contain;

  border: 0px solid #c9c8c883;
}
.name {
  font-weight: 700;
  font-size: 48px;
  line-height: 60px;
  color: #000000;
  margin-bottom: 25px;
  text-align: start;
}
.text {
  font-weight: 400;
  font-size: 22px;
  color: #7A7A7A;
  margin-bottom: 25px;
  text-align: start;
  
}

.card3 {
  padding: 28px 0 83px;
  background: linear-gradient(180deg, #FFFFFF 0%, #F9F9F9 100%);
}

.title {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 24px;
  line-height: 60px;
  margin-bottom: 20px;
}
.item {
  width: 250px;
  flex: 0 0 250px;
  cursor: pointer;
}

.image5 {
  width: 250px;
  height: 200px;
  object-fit: contain;
  margin-bottom: 15px;
}

.name5 {
  font-weight: 500;
  font-size: 17px;
  line-height: 21px;
  color: #2C60F5;
}

.line {
  text-align: start;
  margin-bottom: 20px;
}
.line .name {
  font-size: 22px;
  margin-right: 15px;
  line-height: 0px;
}
/* ==================card6====== */

.card6 {
  padding: 68px 136px 40px;
  text-align: center;
}
.content6 {
  background-color: rgba(44, 96, 245, 0.05);
  border-radius: 16px;
  padding: 60px 0 64px;
}
.title6 {
  font-weight: 700;
  font-size: 40px;
  line-height: 48px;
  margin-bottom: 35px;

}
.desc6 {
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  color: #7A7A7A;
  margin-bottom: 45px;
}
.btn6 {
  display: flex;
  align-items: center;
  gap: 16px;
  justify-content: center;
}
.button6 {
  background: #2C60F5;
  color: #FFFFFF;

  border-radius: 100px;
  height: 40px;
  width: 140px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  cursor: pointer;
}
.button7 {
  background: rgba(255, 255, 255, 0.002);
  box-shadow: inset 0px 0px 0px 1px #BBC2CE;
  color: #000000;
  border-radius: 100px;
  height: 40px;
  width: 140px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  cursor: pointer;
}

.items {
  padding: 0 184px;
}

.videoContainer {
  position: relative;
}

.videoContainer .openVideo {
  color: white;
  position: absolute;
  right: 20px;
  bottom: 20px;
  height: 30px;
  width: 30px;
  opacity: 0.5;
  cursor: pointer;
}

.videoContainer .openVideo:hover {
  opacity: 1;
}



@media screen and (max-width: 750px) {

  .content {
    padding: 48px 0px 0px;
  }
  .items {
    padding: 0;
  }
  

  .card2 {
    padding: 36px 48px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .label {
    font-weight: 700;
    font-size: 64px;
    line-height: 60px;
    margin-bottom: 36px;
  }
  .desc {
    font-weight: 400;
    font-size: 24px;
    line-height: 150%;
    color: #7A7A7A;
    margin-bottom: 78px;
    width: 654px;
  }
  
  .card1 {
    padding: 100px 0 58px;
    display: flex;
    flex-direction: column;
    align-items: center;
   
  }
  
  .image1 {
    width: 750px;
    height: 422px;
    object-fit: cover;
    border: none;
  }
  
  
  .card {
    display: flex;
    align-items: flex-start;
    flex-direction: column-reverse;
    gap: 50px;
    margin-bottom: 50px;
    justify-content: space-between;
  }
  
  .card:last-of-type {
    margin-bottom: 0px;
  }
  
  .left {
  
  }
  .right {
    width: 654px;
    height: 367px;
    object-fit: cover;
    border: 1px solid #D6D6D6;
    border-radius: 30px;
  }
  .name {
    font-weight: 700;
    font-size: 55px;
    line-height: 60px;
    color: #000000;
    margin-bottom: 25px;
  }
  .text {
    font-weight: 400;
    font-size: 24px;
    color: #7A7A7A;
    margin-bottom: 25px;
  }
  
  /* ==================card6====== */

  .card6 {
    padding: 27px 0px;
    text-align: center;
  }
  .content6 {
    background-color: rgba(44, 96, 245, 0.05);
    border-radius: 0;
    padding: 60px 83px 68px;
  }
  .title6 {
    font-weight: 700;
    font-size: 40px;
    line-height: 48px;
    margin-bottom: 35px;

  }
  .desc6 {
    font-weight: 400;
    font-size: 28px;
    line-height: 38px;
    color: #7A7A7A;
    margin-bottom: 45px;
  }
  .btn6 {
    display: flex;
    align-items: center;
    gap: 16px;
    justify-content: center;
  }
  .button6 {
    background: #2C60F5;
    border-radius: 100px;
    height: 60px;
    width: 211px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    color: #FFFFFF;
    cursor: pointer;
  }
  .button7 {
   

    background: rgba(255, 255, 255, 0.002);
    border-radius: 100px;
    height: 60px;
    width: 211px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    color: #000000;
    cursor: pointer;
  }

  /* ================== */
  .card3 {
    padding: 36px 0 60px;
    background: #F8F9FA;
  }
  
  .title {
    margin-bottom: 50px;
  }

}

