

.wrapper {
  padding: 100px 170px 78px;
  background: white;
}

.title {
  font-weight: 700;
  font-size: 38px;
  line-height: 60px;
  color: #7A7A7A;
  margin-bottom: 93px;
}

.content {
  display: grid;
  grid-template-columns: 358px 1fr;
  gap: 76px;
  position: relative;
}

.contentLeft {
  width: 358px;
  height: 334px;
  position: relative;
}

.img {
  width: 358px;
  height: 334px;
}

.rect1 {
  width: 67px;
  height: 67px;
  position: absolute;
  top: -20px;
  left: -34px;
}

.rect2 {
  width: 97.6px;
  height: 97.6px;
  background: #2C60F5;
  position: absolute;
  bottom: -30px;
  right: -13px;
}

.rect3 {
  width: 23.2px;
  height: 23.2px;
  background: #2C60F5;
  position: absolute;
  bottom: -30px;
  right: 94px;
}
.rect4 {
  width: 67px;
  height: 67px;
  position: absolute;
  bottom: -30px;
  right: -64px;
  transform: rotate(180deg);
}

.text {
  font-weight: 400;
  font-size: 20px;
  color: #7A7A7A;
  line-height: 36px;
}

.items {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  justify-content: center;
  margin-top: 107px;
  align-items: center;
}


.item {
  font-weight: 500;
  font-size: 20px;
  color: #7A7A7A;
  white-space: nowrap;
  text-align: center;
}

.number {
  font-weight: 700;
  font-size: 64px;
  color: #000000;
}

.right {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.image {
  width: 154px;
  height: 58px;
}


@media screen and (max-width: 750px) {
  .wrapper {
    padding: 76px 48px 80px;
    background: white;
  }
  
  .title {
    font-weight: 700;
    font-size: 48px;
    line-height: 60px;
    color: #7A7A7A;
    margin-bottom: 107px;
  }
  
  .content {
    display: flex;
    flex-direction: column;
    position: relative;
  }
  
  .contentLeft {
    width: 654px;
    height: 610px;
    position: relative;
  }
  
  .img {
    width: 654px;
    height: 610px;
  }
  
  .rect1 {
    width: 121px;
    height: 121px;
    position: absolute;
    top: -40px;
    left: -40px;
  }
  
  .rect2 {
    width: 171.3px;
    height: 171.3px;
    background: #2C60F5;
    position: absolute;
    bottom: -22px;
    right: -26px;
  }
  
  .rect3 {
    width: 40.72px;
    height: 40.72px;
    background: #2C60F5;
    position: absolute;
    bottom: -22px;
    right: 170px;
  }
  .rect4 {
    display: none;
  }
  
  .text {
    font-weight: 400;
    font-size: 24px;
    color: #7A7A7A;
    line-height: 36px;
  }
  
  .items {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 36px;
    justify-content: center;
    margin-top: 44px;
    align-items: center;
  }
  
  
  .item {
    font-weight: 500;
    font-size: 20px;
    color: #7A7A7A;
    white-space: nowrap;
    text-align: center;
  }
  
  .number {
    font-weight: 700;
    font-size: 64px;
    line-height: 150%;
    color: #000000;
  }
  
  .right {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  
  .image {
    width: 231px;
    height: 87px;
  }
}