.wrapper {
  background-color: #2C60F5;
  padding: 54px 170px 184px;
  position: relative;
  overflow: hidden;
}


.title {
  font-weight: 700;
  font-size: 38px;
  line-height: 60px;
  margin-bottom: 90px;
  color: rgba(255, 255, 255, 0.5);
}



/* ==================== */

.card {
  display: flex;
  color: #FFFFFF;
  /* width: 980px; */
  flex: 0 0 1101px;
  gap: 54px;
  justify-content: space-between;
  padding: 0 2px;
}

.right {
  flex: auto;
}

.name {
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
  margin-bottom: 4px;
}
.text {
  font-weight: 700;
  font-size: 36px;
  line-height: 44px;
  margin-bottom: 4px;
}
.date {
  font-weight: 500;
  font-size: 20px;
  margin-bottom: 20px;
}
.icon {
  width: 154px;
  height: 58px;
  cursor: pointer;
}

.image1 {
  width: 420px;
  height: 260px;
}

.new1 {
  position: absolute;
  width: 260px;
  height: 264px;
  bottom: 12px;
  left: -69px;
}
.new2 {
  position: absolute;
  width: 136px;
  height: 138px;
  top: -27px;
  right: -37px;

}

@media screen and (max-width: 750px) {
  .wrapper {
    padding: 72px 50px 175px;
  }
  
  .title {
    font-weight: 700;
    font-size: 48px;
    line-height: 60px;
    margin-bottom: 51px;
  }


  .card {
    display: flex;
    flex: 0 0 651px;
    flex-direction: column;
  }

  .right {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-right: 5px;
  }

  .image1 {
    width: 651px;
    height: 404px;
    object-fit: cover;
    margin-bottom: 40px;
  }

  .name {
    font-weight: 500;
    font-size: 32px;
    line-height: 30px;
    margin-bottom: 24px;
  }
  .text {
    font-weight: 700;
    font-size: 42px;
    line-height: 52px;
    margin-bottom: 14px;
  }
  .date {
    font-weight: 500;
    font-size: 32px;
    margin-bottom: 30px;
  }
  .icon {
    align-self: flex-end;
    width: 231px;
    height: 87px;
  }
}