.wrapper {
  padding: 100px 170px 78px;
  width: 100%;
  flex: 1 0 100%;
}


.items {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 12px;
  
}

.title {
  font-weight: 700;
  font-size: 38px;
  line-height: 60px;
  color: rgba(122, 122, 122, 1);
  margin-bottom: 80px;
}


.images {
  object-fit: contain;
  width: 100%;
  height: 100%;
}


@media screen and (max-width: 750px) {
  .wrapper {
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: bottom;
    background-size: auto 357px;
    padding: 116px 48px 164px;
  }
}