
.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.content {
  width: 972px;
  padding:  103px 0 46px;
}




/* =============================card */

.card {
  display: flex;
  gap: 66px;
  flex-direction: row-reverse;
  padding-bottom: 66px;
  border-bottom: 1px solid #D6D6D6;
  margin-bottom: 66px;
  justify-content: space-between;
}

.left {

}

.text1 {
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
  color: #E60013;
  margin-bottom: 4px;
}

.text2 {
  font-weight: 700;
  font-size: 36px;
  line-height: 44px;
  color: #000000;
  margin-bottom: 25px;
}

.text3 {
  font-weight: 500;
  font-size: 20px;
  line-height: 150%;
  color: #7A7A7A;
  margin-bottom: 25px;
}

.text4 {
  color: #2C60F5;
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;

}

.icon {
  width: 9px;
  height: 15px;
}
.image {
  width: 400px;
  height: 224px;
  flex: 0 0 400px;
  object-fit: cover;
}


@media screen and (max-width: 750px) {
  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .content {
    padding: 49px 48px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }



  .card {
    display: flex;
    gap: 30px;
    flex-direction: column;
    padding-bottom: 42px;
    border-bottom: 1px solid #D6D6D6;
    margin-bottom: 42px;
  }
  
  .left {
  
  }
  
  .text1 {
    font-weight: 500;
    font-size: 18px;
    line-height: 30px;
    color: #E60013;
    margin-bottom: 13px;
  }
  
  .text2 {
    font-weight: 700;
    font-size: 36px;
    line-height: 44px;
    color: #000000;
    margin-bottom: 13px;
  }
  
  .text3 {
    font-weight: 500;
    font-size: 20px;
    line-height: 150%;
    color: #7A7A7A;
    margin-bottom: 13px;
  }
  
  .text4 {
    color: #2C60F5;
    font-weight: 500;
    font-size: 26px;
    line-height: 45px;
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;
  
  }
  
  .icon {
    width: 12px;
    height: 23px;
  }
  .image {
    width: 654px;
    height: 405px;
    flex: 0 0 405px;
    object-fit: cover;
    border-radius: 30px;
  }
  
}