

.header {
  width: 100%;
  height: 590px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
}

.image {
  width: 100%;
  height: 590px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  padding-top: 170px;
  padding-left: 136px;
  z-index: 10;
}

.name {
  font-weight: 700;
  font-size: 48px;
  line-height: 60px;
  color: #F9F9F9;
  opacity: 0.95;
  margin-bottom: 21px;
}
.title {
  font-weight: 400;
  font-size: 24px;
  line-height: 150%;
  color: #F9F9F9;
  opacity: 0.8;
  margin-bottom: 50px;
  /* width: 598px; */

}
.button {
  width: 154px;
  height: 58px;
  cursor: pointer;

}
.mask {
  width: 100%;
  height: 590px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  background: rgba(11, 60, 199, 0.3);
}


@media screen and (max-width: 750px) {


  .header {
    width: 100%;
    height: 750px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: relative;
  }
  
  
  .image {
    width: 100%;
    height: 750px;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    padding: 170px 48px 0;
    z-index: 10;
  }
  
  .name {
    font-weight: 700;
    font-size: 48px;
    line-height: 60px;
    color: #F9F9F9;
    opacity: 0.95;
    margin-bottom: 26px;
  }
  .title {
    font-weight: 400;
    font-size: 24px;
    line-height: 150%;
    color: #F9F9F9;
    opacity: 0.8;
    margin-bottom: 70px;
    width: 100%;
  
  }
  .button {
    width: 231px;
    height: 87px;
    cursor: pointer;
  
  }
  .mask {
    width: 100%;
    height: 750px;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    background: rgba(11, 60, 199, 0.3);
  }
}