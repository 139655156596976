.modal {
  position: absolute;
  width: 779px;
  height: 80vh;
  background: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(7.5px);
  padding: 38px 64px 50px;
  display: flex;
  flex-direction: column;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  
}
.content {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}
.descs {
  display: flex;
  flex-direction: column;
  gap: 10px
}

.content::-webkit-scrollbar {
  display: none;
}

.date {
  font-weight: 700;
  font-size: 24px;
  line-height: 35px;
  color: #333333;
  margin-bottom: 5px;
}
.name {
  font-weight: 700;
  font-size: 32px;
  line-height: 46px;
  color: #888888;
  margin-bottom: 13px;

}
.text {
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  color: #111111;
  margin-bottom: 28px;
}
.image {
  width: 100%;
  height: auto;
  object-fit: cover;
  margin-bottom: 18px;
}
.desc {
  font-weight: 400;
  font-size: 17px;
  line-height: 25px;
  color: #333333;
}
.left {
  position: absolute;
  bottom: 80px;
  left: -310px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 300px;
}
.right {
  position: absolute;
  bottom: 80px;
  right: -310px;
  display: flex;
  flex-direction: column;
  width: 300px;
}

.year1 {
  font-weight: 700;
  font-size: 24px;
  line-height: 35px;
  color: rgba(255, 255, 255, 0.9);

}
.lable1 {
  font-weight: 700;
  font-size: 32px;
  line-height: 46px;
  color: rgba(255, 255, 255, 0.9);
}
.expend {
  position: absolute;
  width: 48px;
  height: 48px;
  top: 8px;
  right: 8px;
  cursor: pointer;

  color: #FFFFFF;
}

.button {
    cursor: pointer;
    user-select: none;
    opacity: 0.8;
}

.button:hover  {
  opacity: 1;
}

@media screen and (max-width: 750px) {

  .modal {
    top: 24px;
    right: 24px;
    left: 24px;
    bottom: 24px;
    /* width: ; */
    width: auto;
    height: auto;
    /* height: 80vh; */
    padding: 40px 24px;
    transform: translate(0, 0);
  }

  .date {
    font-weight: 700;
    font-size: 32px;
    line-height: 46px;
    color: #333333;
    margin-bottom: 5px;
  }
  .name {
    font-weight: 700;
    font-size: 40px;
    line-height: 58px;
    color: #888888;
    margin-bottom: 12px;
  
  }
  .text {
    font-weight: 700;
    font-size: 48px;
    line-height: 60px;
    color: #000000;
    margin-bottom: 40px;
  }
  .image {
    width: 100%;
    height: auto;
    object-fit: cover;
    margin-bottom: 33px;
  }
  .desc {
    font-weight: 400;
    font-size: 32px;
    line-height: 50px;
    color: #888888;
  }

  .expend {
    width: 85px;
    height: 85px;
  }
  .left {
    display: none;
  }
  .right {
    display: none;
  }

}