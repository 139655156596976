

.slices {
  width: 100%;
  position: relative;
}

.slice {
  width: 100%;
  overflow: hidden;
  display: flex;
 
}

.items {
  display: flex;
  transition: transform .5s;
 
}

.contentItems {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 4px 6px;
  /* gap: 24px; */
  box-sizing: border-box;
}

/* ===================================== */

.button {
  display: flex;
  justify-content: center;
  align-items: center;
}

.button:hover {
  background-color: #2C60F5;
}



.left {
  position: absolute;
  top: 50%;
  left: -58px;
  transform: translateY(-50%);
  width: 36px;
  height: 48px;
  background: rgba(0, 0, 0, 0.2);
  flex: 1 0 36px;
  cursor: pointer;
}

.right {
  position: absolute;
  display: flex;
  top: 50%;
  right: -58px;
  transform: translateY(-50%);
  width: 36px;
  height: 48px;
  flex: 1 0 36px;
  background: rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.dots {
  position: absolute;
  display: flex;
  gap: 18px;
  left: 50%;
  bottom: -50px;
  transform: translateX(-50%);
  cursor: pointer;
}
.dot:hover {
  background: #2C60F5;
}

.dots > span {
  width: 12px;
  height: 12px;
  background: #000000;
  border-radius: 50%;
}

.arrow {
  width: 10px;
  height: 17px;
}

.swipers {
  overflow-x: auto;
  overflow-y: hidden;
  gap: 45px;
  display: flex;
  padding: 4px 48px;
  /* padding: 4px 48px; */
}

.swipers::-webkit-scrollbar {
  display: none;
}


@media screen and (max-width: 750px) {
  .slice {
    width: 100%;
    display: flex;
    overflow: visible;
  }
  .items {
    overflow-x: auto;
    overflow-y: hidden;
  }

  .items::-webkit-scrollbar {
    display: none;
  }
  .right {
    display: none;
  }
  .left {
    display: none;
  }
}