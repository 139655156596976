
.slice {
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.progress {
  position: absolute;
  bottom: 20px;
  z-index: 9999;
  right: 20px;
  display: none;
}

.navs {
  position: relative;
}

.navs .left path {
  fill: white;
}
.navs .right path {
  fill: white;
}

.nav {
  position: absolute;
  top: 50%;
  display: flex;
  transform: translateY(-50%);
  width: 0.48rem;
  height: 0.64rem;
  background: rgba(0, 0, 0, 0.1);
  flex: 1 0 0.48rem;
  cursor: pointer;
  z-index: 9999;
  justify-content: center;
  align-items: center;
}

.nav:hover {
  background: rgba(0, 0, 0, 0.3);
}

.navs .left {  
  left: 30px;
}

.navs .right {
  right: 30px;
}



.items {
  display: flex;
  
}
.wrapper {
  background-repeat: no-repeat;
  background-position: center;
  height: calc(100vw/2.44);
  background-size: 100% calc(100vw/2.44);
  position: relative;
  width: 100%;
  flex: 1 0 100%;
}

.image1 {
  width: 100%;
  height: calc(100vw/2.44);
  object-fit: cover;
}

.image {
  width: 100%;
  height: calc(100vw/2.44);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  padding-top: 174px;
  padding-left: 169px;
  z-index: 10;
}

.name {
  font-weight: 700;
  font-size: 48px;
  line-height: 60px;
  color: #F9F9F9;
  opacity: 0.95;
  margin-bottom: 21px;
}
.title {
  font-weight: 400;
  font-size: 24px;
  line-height: 150%;
  color: #F9F9F9;
  opacity: 0.8;
  margin-bottom: 50px;
  width: 8.8rem;

}
.button {

}
.mask {
  width: 100%;
  height: calc(100vw/2.44);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  background: rgba(11, 60, 199, 0.3);
}

.detail_contaner {
  display: flex;
  gap: 30px;
  align-items: center;
}

.openVideo {
  color: white;
  cursor: pointer;
  width: 30px;
  height: 30px;
  opacity: 0.5;
}

.openVideo:hover {
  opacity: 0.8;
}


@media screen and (max-width: 750px) {
  .wrapper {
    background-repeat: no-repeat;
    background-position: center;
    height: 750px;
    background-size: 100% 750px;
    position: relative;
    width: 100%;
    flex: 1 0 100%;
  }

  .image {
    width: 100%;
    height: 750px;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    padding: 206px 48px 0px;
    z-index: 10;
  }

  .image1 {
    width: 100%;
    height: 750px;
  }
  

  .mask {
    width: 100%;
    height: 750px;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    background: rgba(11, 60, 199, 0.3);
  }
  

 
}