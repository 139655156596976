.center {
  min-height: 80px;
  width: 100%;
  background: rgba(255, 255, 255, 0.95);
  padding:  10px 136px;
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
  position: sticky;
  top: 0;
  z-index: 100;
  margin-top: 20px;
}

.catalog {
  display: flex;
  align-items: center;
  gap: 25px;
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
  flex-wrap: wrap;
  color: #111111;
}

.item {
  cursor: pointer;
  white-space: nowrap;
  display: flex;
  align-items: center;
  padding: 6px 18px;
  height: 42px;
}
.active {
  background: rgb(122, 122, 122, 0.1);
  border-radius: 83px;
  color: #7A7A7A;
}
.right {
  display: flex;
  /* align-items: center; */
  gap: 15px;
}

.input {
  font-weight: 500 !important;
  font-size: 20px !important;
  line-height: 30px !important;
  color: #7A7A7A !important;
  width: 257px !important;
  height: 60px !important;
  padding: 15px 18px !important; 
  border: 1px solid #D6D6D6 !important;
  border-radius: 12px !important;
}

.icon {
  width: 23px;
  height: 23px;
}


@media screen and (max-width: 750px) {

  .center {
    height: 93px;
    width: 100%;
    background: rgba(255, 255, 255, 0.95);
    padding: 0 48px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .catalog {
    display: flex;
    align-items: center;
    gap: 20px;
    font-weight: 500;
    font-size: 24px;
    line-height: 45px;
    color: #111111;
    overflow-x: auto;
    overflow-y: hidden;

  }
  .catalog::-webkit-scrollbar {
    display: none;
  }
  .right {
    display: none;
  }
}