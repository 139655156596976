


:root,
:host {
  --w-e-textarea-bg-color: #fff;
  --w-e-textarea-color: #333;
  --w-e-textarea-border-color: #ccc;
  --w-e-textarea-slight-border-color: #e8e8e8;
  --w-e-textarea-slight-color: #d4d4d4;
  --w-e-textarea-slight-bg-color: #f5f2f0;
  --w-e-textarea-selected-border-color: #B4D5FF;
  --w-e-textarea-handler-bg-color: #4290f7;
  --w-e-toolbar-color: #595959;
  --w-e-toolbar-bg-color: #fff;
  --w-e-toolbar-active-color: #333;
  --w-e-toolbar-active-bg-color: #f1f1f1;
  --w-e-toolbar-disabled-color: #999;
  --w-e-toolbar-border-color: #e8e8e8;
  --w-e-modal-button-bg-color: #fafafa;
  --w-e-modal-button-border-color: #d9d9d9;
}

.editor {
  /* border: 3px solid #ccc; */
  border-radius: 5px;
  padding: 0 10px;
  /* margin-top: 20px; */
  overflow-x: auto;
  font-weight: 400;
  font-size: 17px;
  color: #333333;
}

.editor p,
.editor li {
  white-space: pre-wrap; /* 保留空格 */
}

.editor a[data-w-e-type="attachment"] {
  display: inline-block;
    margin-left: 3px;
    margin-right: 3px;
    /* border: 2px solid var(--w-e-textarea-selected-border-color); */
    border-radius: 3px;
    padding: 0px 6px;
    background-color: rgb(241, 241, 241);
    cursor: pointer;
    color: #333333;

}

.editor blockquote {
  border-left: 8px solid #d0e5f2;
  padding: 10px 10px;
  margin: 10px 0;
  background-color: #f1f1f1;
}

.editor code {
  font-family: monospace;
  background-color: #eee;
  padding: 3px;
  border-radius: 3px;
}
.editor pre>code {
  display: block;
  padding: 10px;
}

.editor table {
  border-collapse: collapse;
}
.editor td,
.editor th {
  border: 1px solid #ccc;
  min-width: 50px;
  height: 20px;
}
.editor th {
  background-color: #f1f1f1;
}

.editor ul,
.editor ol {
  padding-left: 20px;
}

.editor input[type="checkbox"] {
  margin-right: 5px;
}

.editor h1 {
  font-size: 46px;
}
.editor h2 {
  font-size: 36px;
  min-height: 1em;
}
.editor h3 {
  font-size: 26px;
}
.editor h4 {
  font-size: 22px;
}
.editor h5 {
  font-size: 16px;
}


.editor img {
  display: inline-block;
}
.editor video {
  width: 100%;
  height: auto;
}


@media screen and (max-width: 750px) {
  .editor h1 {
    font-size: 60px;
  }
  .editor h2 {
    font-size: 50px;
  }
  .editor h3 {
    font-size: 40px;
  }
  .editor h4 {
    font-size: 36px;
  }
  .editor h5 {
    font-size: 30px;
  }
  
  .editor {
    font-weight: 400;
    font-size: 32px;
    color: #888888;
  }

  .editor table {
    border-collapse: collapse;
    font-size: 14px !important;
  }
}