

.wrapper {
  padding-bottom: 25px;
}

.title {
  font-weight: 500;
  font-size: 24px;
  line-height: 30px;
  color: #2C60F5;
  padding: 27px 48px 0;
}
