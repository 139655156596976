


.wrapper {
  width: 800px;
  padding: 74px 0;
  margin: 0 auto;
}
.catalog {
  font-weight: 700;
  font-size: 12px;
  line-height: 32px;
  color: #E60013;
  margin-bottom: 4px;
}
.date {
  font-weight: 600;
  font-size: 12px;
  line-height: 32px;
  color: #888888;
  margin-bottom: 20px;
}
.desc {
  font-weight: 700;
  font-size: 48px;
  line-height: 52px;
  color: #111111;
  margin-bottom: 59px;
}

.divder {
  border-bottom: 1px solid #D6D6D6;
  height: 0;
  width:  100%;
  margin-bottom: 24px;
}

.content p {
  align-items: start !important;
}

@media screen and (max-width: 750px) {
  .wrapper {
    width: 100%;
    padding: 77px 48px;
    display: flex;
    flex-direction: column;
  }
 
}
