
.arrow {
  width: 13px;
  height: 23px;
}


@media screen and (max-width: 750px) {


  
}