

.code {
  display: flex;
  gap: 19px;
  align-items: center;
}
.image {
  /* min-width: 2rem;
  min-height: 1rem; */
  width: 119px;
  height: 42px;
  cursor: pointer;
}

.ant-form-horizontal .ant-form-item-label{
  text-align: left;
}

.input {
  height: 2.7em;
}



@media screen and (max-width: 575px) {
}