
.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.content {
  width: 972px;
  padding:  103px 0 46px;
 
}

.card {
  display: flex;
  justify-content: space-between;
  padding-bottom: 37px;
  border-bottom: 1px solid #D6D6D6;
  margin-bottom: 38px;
  align-items: center;
}
.left {

}
.text1 {
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
  color: #E60013;
  margin-bottom: 6px;
}
.text2 {
  font-weight: 500;
  font-size: 32px;
  line-height: 32px;
  color: #2C60F5;
  margin-bottom: 6px;
}
.text3 {
  font-weight: 700;
  font-size: 16px;
  line-height: 32px;
  color: #111111;
  margin-bottom: 6px;
}
.text4 {
  font-weight: 500;
  font-size: 20px;
  line-height: 150%;
  color: #7A7A7A;
  margin-bottom: 6px;
}
.text5 {
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
  color: #2C60F5;
  display: flex;
  gap: 5px;
  align-items: center;
  cursor: pointer;
}
.icon {
  width: 12px;
  height: 23px;
}
.image {
  width: 400px;
  height: 224px;
}

.switch {
  display: flex;
  align-items: center;
  gap: 14px;
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
}


.page {
  display: flex;
  justify-content: center;
}
@media screen and (max-width: 750px) {
  .content {
    width: 100%;
    padding: 48px;
   
  }
  
  .card {
    display: flex;
    flex-direction: column-reverse;

    padding-bottom: 37px;
    border-bottom: 1px solid #D6D6D6;
    margin-bottom: 38px;
    align-items: flex-start;
  }
  .left {
  
  }
  .text1 {
    font-weight: 500;
    font-size: 24px;
    line-height: 30px;
    color: #E60013;
    margin-bottom: 13px;
  }
  .text2 {
    font-weight: 700;
    font-size: 42px;
    line-height: 60px;
    color: #2C60F5;
    margin-bottom: 13px;
  }
  .text3 {
    font-weight: 500;
    font-size: 24px;
    line-height: 30px;
    color: #111111;
    margin-bottom: 13px;
  }
  .text4 {
    font-weight: 500;
    font-size: 32px;
    line-height: 150%;
    color: #888888;
    margin-bottom: 13px;
  }
  .text5 {
    font-weight: 500;
    font-size: 26px;
    line-height: 45px;
    color: #2C60F5;
    display: flex;
    gap: 5px;
    align-items: center;
    cursor: pointer;
  }
  .image {
    width: 654px;
    height: 405px;
    object-fit: cover;
    border-radius: 30px;
    margin-bottom: 30px;
  }
  

}