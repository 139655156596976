html {
  height: 100vh;
}

html,
body {
  padding: 0;
  margin: 0;
  font-size: 15px;
  line-height: 1.6;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff;
  /* background: #f8f8f8; */

  color: #212b36;
  font-family: system-ui, Helvetica Neue, PingFang SC, Microsoft YaHei,
    Source Han Sans SC, Noto Sans CJK SC, WenQuanYi Micro Hei, sans-serif;
}

@media screen and (max-device-width: 480px) {
  body {
    -webkit-text-size-adjust: 100%;
  }
}

body {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100vh;
}

#app {
  height: 100%;
}

* {
  box-sizing: border-box;
}

textarea,
input,
button {
  -webkit-appearance: none;
  font: inherit;
}

button {
  padding: 0;
  margin: 0;
  font: inherit;
}

[role='button'] {
  cursor: pointer;
}

a,
[role='link'] {
  text-decoration: none;
  color: #0751aa;
  cursor: pointer;
}

img,
svg {
  display: block;
}

html[data-platform~='Wechat'][data-platform~='iOS'] img {
  -webkit-touch-callout: none;
}

@media (min-width: 960px) {
  html,
  body {
    /* background: #f9f9f9; */
  }
}


:root {
  --main-color: #2C60F5;
}


