

.wrapper {
  width: 700px;
  padding: 74px 0;
  margin: 0 auto;
}
.catalog {
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  color: #E60013;
  margin-bottom: 9px;
  text-align: center;
}
.title {
  font-weight: 700;
  font-size: 48px;
  line-height: 52px;
  color: #111111;
  margin-bottom: 22px;
  text-align: center;
}
.date {
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  color: #888888;
  margin-bottom: 23px;
  text-align: center;

  
}
.back {
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
  color: #2C60F5;
  margin-bottom: 32px;
  text-align: center;
  display: flex;
  align-items: center;
  gap: 18px;
  justify-content: center;
  cursor: pointer;
}

.backIcon {
  width: 9px;
  height: 16px;
}

.content {
  display: grid;
  grid-template-columns: 120px 1fr 120px 1fr ;
  grid-column-gap: 15px;
  grid-row-gap: 10px;
}

.content1 {
  display: grid;
  grid-template-columns: 120px 1fr;
  grid-column-gap: 15px;
  grid-row-gap: 39px;
}

.divder {
  height: 0;
  width: 100%;
  border-bottom: 1px solid #D6D6D6;
}
.divder1 {
  height: 0;
  width: 100%;
  border-bottom: 1px solid #D6D6D6;
}

.footer {
  font-weight: 600;
}
.label {
  font-weight: 700;
 
  color: #333333;
  white-space: nowrap;
}
.contentTitle {
  font-weight: 700;
}

.content1 p {
  text-indent: 0em;
  margin-bottom: 0px;
  letter-spacing: 1px;
}


@media screen and (max-width: 750px) {
  .wrapper {
    padding:  0;
    margin: 0;
    width: 100%;
  }
  .head {
    padding: 30px 48px;
  }
  .catalog {
    font-weight: 700;
    font-size: 26px;
    line-height: 31px;
    color: #E60013;
    margin-bottom: 15px;
    text-align: start;
    width: 100%;
  }
  .title {
    font-weight: 700;
    font-size: 48px;
    line-height: 60px;
    color: #111111;
    margin-bottom: 20px;
    text-align: start;
  }
  .date {
    font-weight: 600;
    font-size: 26px;
    line-height: 31px;
    color: #888888;
    margin-bottom: 18px;
    text-align: start;
    
  }
  .back {
    font-weight: 500;
    font-size: 26px;
    line-height: 45px;
    color: #2C60F5;
    margin-bottom: 0;
    text-align: center;
    display: flex;
    align-items: center;
    gap: 18px;
    justify-content: flex-start;
  }
  
  .backIcon {
    width: 9px;
    height: 16px;
  }
  
  .content {
    background: #F8F9FA;
    display: grid;
    padding: 32px 48px;
    grid-template-columns: 159px 1fr;
    grid-column-gap: 24px;
    grid-row-gap: 17px;
    color: #888888;
    font-weight: 700;
    font-size: 32px;
    line-height: 50px;
  }
  
  .label {
    font-weight: 700;
    font-size: 32px;
    line-height: 50px;
    color: #333333;
    white-space: nowrap;
  }
  .content1 {
    display: grid;
    grid-template-columns: 1fr;
    padding: 23px 48px;
    grid-column-gap: 0;
    grid-row-gap: 0;
  }

  .content1 li {
    font-weight: 400 !important;
    font-size: 32px !important;
    line-height: 50px !important;
    color: #888888 !important;
  }

  .contentTitle {
    font-weight: 700;
    font-size: 32px;
    line-height: 50px;
    color: #111111;
    margin-bottom: 18px;
  }
  
  .divder {
    display: none;
  }
  
  .footer {
    padding-bottom: 100px;
    font-weight: 600;
  }

}