
.wrapper {
  
}
.content {
  padding: 50px 138px 100px 134px 
}

@media screen and (max-width: 750px) {
  .content {
    padding: 46px 48px;
  }
  
}
