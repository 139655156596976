



.links {
  display: flex;
  white-space: nowrap;
  height: 100%;
  align-items: center;
}

.link {
  /* padding: 0 24px; */
  width: 108px;
  display: flex;
  height: 100%;
  align-items: center;
  white-space: nowrap;
  color: #000000;
  box-sizing: border-box;
  position: relative;
  justify-content: center;
}

.link:hover > a {
  color: #000000;
}

.contact {
  background-color:#2C60F5;
  color: white;
}

.contact:hover > a {
  color: white;
}

.link > a {
  font-weight: 400;
  font-size: 15px;
  line-height: 26px;
  color: #000000;
}
.contact > a {
  color: white;
}

.divier {
  height: 4px;
  width: 72px;
  background: #2C60F5;
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
}

.selected > a {
  font-weight: 600;
}

.dropMenu {
  position: absolute;
  top: 10px;
  left: 0;
  right: 0;
  width: 108px;
  z-index: 1000;
}


.logo {
  display: flex;
  align-items: center;
  gap: 21px;
}

.logo1 {
  width: 48px;
  height: 58px;

}
.logo2 {
  width: 332px;
  height: 42px;
}



/* ============dropDown2====== */

.dropDown2 {
  position: relative;
  height: 75px;
}

.dropDown2Name {
  height: 76px;
  padding-top: 19px;
  text-align: center;
  font-weight: 400;
  font-size: 15px;
  line-height: 26px;
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  z-index: 1000;
  background: rgba(255, 255, 255, 0.8);
  border: 0.01333rem solid #D6D6D6;
  -webkit-backdrop-filter: blur(0.06667rem);
  backdrop-filter: blur(0.06667rem);
  border-bottom: none;
}

 .dropDown2Menu {
  position: absolute;
  top: 85px;
  left: 0px;
  width: 100%;
  right: 0px;
  /* right: 0; */
  padding: 30px 70px;
  background: rgba(255, 255, 255, 0.8);
  border: 1px solid #D6D6D6;
  backdrop-filter: blur(5px);
  display: grid;
  grid-row-gap: 18px;
  grid-column-gap: 25px; 
  z-index: 999;
  justify-items: center;
  justify-content: center;
} 

.dropItem {
  width: 100%;
  max-width: 400px;
  min-width: 200px;
  align-content: center;
}

.dropItem:hover > .dropText {
  background: #2C60F5;
  color:white;
}

.dropImage  {
  width: 100%;
  height: 180px;
  object-fit: cover;

}
.dropText {
  height: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

