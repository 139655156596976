

.wrapper {
background-color: #ffffff;
background-image: url("https://www.transparenttextures.com/patterns/light-wool.png");
/* This is mostly intended for prototyping; please download the pattern and re-host for production environments. Thank you! */
 
  }
.content {
  display: flex;
  /* align-items: center; */
  flex-direction: column;
  padding: 70px 0 143px;
}

.content img {
  cursor: pointer;
}


.slice1 {
  padding: 0 249px 0;
  margin-bottom: 138px;
  display: flex;
  flex-direction: column;
}

.slice2 {
  padding: 0 179px 0;
}

.top {
  display: flex;
  align-items: center;
  gap: 54px;
  margin-bottom: 80px;
  align-self: center;
}
.item {
  display: flex;
  align-items: center;
  gap: 18px;
  width: 307px;
}
.rect {
  width: 58px;
  height: 106px;
  flex: 1 0 58px;
}
.c1 {
  text-align: center;
}
.name {
  font-weight: 700;
  font-size: 64px;
  line-height: 70px;
  white-space: nowrap;
}
.t1 {
  font-weight: 500;
  font-size: 20px;
  white-space: nowrap;
}


/* ====================== */



.image1 {
  width: 247px;
  height: 350px;
  object-fit: cover;
}
.image2 {
  width: 335px;
  height: 230px;
}


@media screen and (max-width: 750px) {
  .slice1 {
    padding: 0;
    width: 100%;
    margin-bottom: 87px;

  }
  
  .slice2 {
    padding: 0;
    width: 100%;
  }

  .imgs {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 4px 0;
    gap: 35px;
  }
  
  .imgs1 {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 4px 0;
    gap: 35px;
  }
}