

.wrapper {
  background-repeat: no-repeat;
  /* background-position: center 0; */
  background-position-x: center;
  background-position-y: bottom;
  background-size: auto 357px;
  padding: 54px 170px 164px;
}


.title {
  font-weight: 700;
  font-size: 38px;
  line-height: 60px;
  color: rgba(122, 122, 122, 1);
  margin-bottom: 136px;
}


.item  {
  display: flex;
  gap: 60px;
  width: 1100px;
  flex: 0 0 1100px;
  justify-content: space-between;
}

.left {
  /* width: 177px; */
  height: 160px;
}

.right {
  font-weight: 400;
  font-size: 32px;
  width: 732px;
  flex: auto;
}

.text  {
  font-weight: 400;
  font-size: 20px;
  line-height: 150%;
  color: #000000;
  margin-bottom: 15px;
}

.name {
  font-weight: 400;
  font-size: 24px;
  line-height: 30px;
  color: #2C60F5;
  margin-bottom: 20px;
}

.sub {
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  color: #000000;
}


/* ======================= */

.wrapper1 {
  height: 157px;
  padding: 0 122px;
  animation: decline 1s ease 0s 1 normal forwards;
  /* background: linear-gradient(180deg, #F9F9F9 0%, #FFFFFF 100%); */
}

.wrapper2 {

  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: repeat(6, 1fr);
  padding: 20px 50px 40px;
  grid-row-gap: 40px;
  height: auto;

  background: linear-gradient(180deg, #F9F9F9 0%, #FFFFFF 100%);

}
.wrapper2 > div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.image2 {
   width: 76px;
  height: 76px; 
  /* position: relative;
  margin: 0px 100px;
  padding: 0px 500px; */
}

.image3 {
  /* width: 150px; */
  height: 80px;
  width: 119;
}

/* .dot {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -10px;
  width: 8px;
  height: 8px;
  background: #2C60F5;
  border-radius: 50%;
} */

@media screen and (max-width: 750px) {


  .wrapper {
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: bottom;
    background-size: auto 357px;
    padding: 116px 48px 164px;
  }
  
  
  .title {
    font-weight: 700;
    font-size: 48px;
    line-height: 60px;
    color: rgba(122, 122, 122, 1);
    margin-bottom: 66px;
  }
  
  
  .item  {
    display: flex;
    width: 654px;
    flex: 0 0 654px;
    justify-content: center;
  }
  
  .left {
    display: none;
  }
  
  .right {
    font-weight: 400;
    font-size: 32px;
    width:  654px;
  }
  
  .text  {
    font-weight: 400;
    font-size: 24px;
    line-height: 150%;
    color: #000000;
    margin-bottom: 38px;
  }
  .name {
    font-weight: 400;
    font-size: 36px;
    line-height: 30px;
    color: #2C60F5;
    margin-bottom: 33px;
  }
  
  .sub {
    font-weight: 400;
    font-size: 32px;
    line-height: 32px;
    color: #7A7A7A;
  }


 

}
