.topbar {
  width: 100%;
  padding: 0 0 0 52px;
  height: 86px;
  flex: 0 0 86px;
  display: flex;
  align-items: center;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  justify-content: space-between;
  cursor: pointer;
  position: relative;
  z-index: 1000;
}

.logo {
  display: flex;
  align-items: center;
  gap: 20px;
}

.menu {
  display: none;
}

.logo1 {
  width: 48px;
  height: 58px;
}

.logo2 {
  width: 330px;
  height: 42px;
}

.links {
  height: 100%;
}

@media screen and (max-width: 750px) {
  .topbar {
    height: 98px;
    padding: 0 48px;
    flex: 0 0 98px;
  }
  .logo1 {
    width: 60px;
    height: 73px;
  }
  .logo2 {
    display: none;
  }
  .links {
    display: none !important;
    height: 100%;
  }

  .menu {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 42px;
    height: 42px;
  }
}


