.app {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 100vh;
  width:100vw;
  /* max-width: 1440px; */
  /* max-width: 1300px; */
  /* max-width: 1000px; */
  /* max-width: 800px; */
  
}

.main {
  overflow-y: overlay;
  flex: 1 1 auto;
  position: relative;
  overflow-x: hidden;
}
.main::-webkit-scrollbar {
  display: none;
}
.footer {
}

.top {
  flex: 1 0 87px;

}

.headBanner {
  flex: 0 0 80px;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.headImage {
  width: 32px;
  height: 32px;
  object-fit: cover;
  cursor: pointer;
  position: absolute;
  right: 36px;
  top: 50%;
  transform: translateY(-50%);
}

.content {
  font-size: 1.2em;
  line-height: 1.7em;
  text-indent: 2em;
  letter-spacing: 0.2em;
  margin: 10px 0px;
  color: #616161;
}

