

.content {
  width: 652px;
  margin: 0 auto;
  padding: 42px 0 0;
}
.map {
  width: 652px;
  height: 480px;
  margin-bottom: 15px;
}
.divider {
  height: 0;
  width: 100%;
  margin-bottom: 35px;
  border-bottom: 1px solid #D6D6D6;
}
.title {
  display: flex;
  align-items: center;
  gap: 30px;
  font-weight: 350;
  font-size: 20px;
  line-height: 60px;
}
.active {
  font-weight: 700;
  
  box-sizing: border-box;
  position: relative;
}
.active::before {
  position: absolute;
  bottom: 0px;
  height: 4px;
  top: 0px;
  width: 50px;
  border-bottom: 4px solid #2C60F5;
  left: 50%;
  transform: translateX(-50%);
}
.activeH {
  position: absolute;
  bottom: 0px;
  height: 4px;
  width: 100%;
  border-bottom: 4px solid #2C60F5;
  left: 50%;
  transform: translateX(-50%);
}
.items {
  /* padding-top: 37px; */
}
.item {
  margin-bottom: 35px;
}
.name {
  font-weight: 500;
  font-size: 24px;
  line-height: 30px;
  margin-bottom: 13px;
}
.text {
  font-weight: 350;
  font-size: 20px;
  line-height: 25px;
}
.bold {
  font-weight: 700;
}

.title1 {
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 24px;
  line-height: 60px;
  margin-bottom: 40px;
}

@media screen and (max-width: 750px) {


}