.wrapper {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.85);
  z-index: 10000;
  padding: 58px 228px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 37px;
}
.name {
  font-weight: 700;
  font-size: 36px;
  line-height: 60px;
  color: #FFFFFF;
}
.item {
  display: flex;
  gap: 65px;
}

.item p {
  color: #dbdbdb!important;
}

.left {
  flex: 0 0 448px;
}
.image {
  width: 448px;
  height: 345px;
  margin-bottom: 12px;
  object-fit: contain;
  background: #FFFFFF;
  border: 1px solid #D6D6D6;
  border-radius: 12px;
}
.active {
  border: 3px solid #2C60F5;
}
.image1 {
  width: 100%;
  height: 106px;
  object-fit: contain;
  border-radius: 12px;
  background: #FFFFFF;
}
.images {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 15px;
}
.text {
  width: 448px;
  height: 554px;
  font-weight: 700;
  font-size: 20px;
  line-height: 150%;
  color: #F9F9F9 !important;
  word-wrap: break-word;
  overflow-y: auto;
}


.text span {
  color: white
}

.bar {
  flex: 0 0 60px;
  display: flex;
  align-items: center;
  gap: 36px;
}
.leftBar {
  width: 128px;
  height: 60px;
  gap: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #111111;
  border-radius: 8px;
}
.icon {
  width: 48px;
  height: 48px;
  cursor: pointer;
  color: #FFFFFF;
}
.rightBar {
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #111111;
  border-radius: 8px;

}
