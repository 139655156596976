.content {
  padding: 33px 0;
  padding-top: 30px;
}

.content div[data-w-e-type='video'] {
  display: flex;
  justify-content: center;
}

.card1 {
  padding: 0 136px;
  margin-bottom: 80px;
}
.card2 {
  padding: 0 219px;
  margin-bottom: 86px;
}
.card2Content {
  /* padding: 0 80px; */
}
.item {
  display: flex;
  flex-direction: column;
  gap: 15px;
  cursor: pointer;
}
.image2 {
  width: 1003px;
  height: 578px;
  object-fit: cover;
}
.image3  {
  width: 250px;
  height: 200px;
  flex: 0 0 250px;
 
  border: 1px solid #D6D6D6;
  border-radius: 6px;
  object-fit: contain;
  background: #FFFFFF;
}
.title3 {
  font-weight: 500;
  font-size: 17px;
  line-height: 21px;
  color: #2C60F5;
  padding: 0 4px;
}
.card3 {
  margin-bottom: 80px;
  padding: 0 184px;
}

.card4 {
  margin-bottom: 80px;
  background: #F8F9FA;
  padding: 20px 184px 80px;
}

.items4 {
  display: flex;
  align-items: center;
  width: 1036px;
  padding: 4px;
  gap: 24px;
  box-sizing: border-box;
}
.items {
  display: flex;
}
.title {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 24px;
  line-height: 60px;
  margin-bottom: 20px;
}

.back {
  padding-left: 48px;
  padding: 0 136px;
  display: flex;
  align-items: center;
  gap: 18px;
  cursor: pointer;
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
  color: #2C60F5;
  margin-bottom: 37px;
}

.backIcon {
  width: 9px;
  height: 15px;
  transform: rotate(180deg);
}

/* =============== */
.item2 {
  flex: 0 0 500px;
  background: #FFFFFF;
  box-shadow: 0px 2px 8px rgba(95, 126, 155, 0.2);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  overflow: hidden;

}

.cover {
  width: 100%;
  height: 222px;
  object-fit: cover;
}
.itemTitle {
  font-weight: 500;
  font-size: 32px;
  line-height: 40px;
  padding: 30px;
}
.button {
  font-weight: 500;
  font-size: 26px;
  line-height: 45px;
  padding: 0 30px 25px;
  color: #2C60F5;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
}

.icon {
  width: 13px;
  height: 23px;
}

.card5 {
  width: 650px;
  margin: 0 auto;
}

@media screen and (max-width: 750px) {
  .content {
    padding: 46px 0;
  }

  .back {
    margin: 50px 0px;
    padding: 0 48px;
  }
  .back span {
    font-size: 28px !important;
  }

  .card1 {
    padding: 0 48px;
    margin-bottom: 80px;
  }

  .card2 {
    padding: 0;
    margin-bottom: 36px;
  }
  .card2Content {
    padding: 78px 48px;
    background: #F8F9FA;

  }

  .card3 {
    padding: 0;
    margin-bottom: 80px;
  }
  .card4 {
    background: #F8F9FA;
    padding: 0;
    margin-bottom: 80px;
  }
  .card5 {
    width: 100%;
    padding: 0 48px;
  }

  .image2 {
    width: 593px;
    height: 342px;
    object-fit: cover;
  }

  .image3 {
    width: 375px;
    height: 300px;
    flex: 0 0 375px;
  }

  .title3 {

  }


  .item2 {
    flex: 0 0 500px;
    background: #FFFFFF;
    box-shadow: 0px 2px 8px rgba(95, 126, 155, 0.2);
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    overflow: hidden;

  }

  .cover {
    width: 100%;
    height: 222px;
    object-fit: cover;
  }
  .itemTitle {
    font-weight: 500;
    font-size: 32px;
    line-height: 40px;
    padding: 30px;
  }
  .button {
    font-weight: 500;
    font-size: 26px;
    line-height: 45px;
    padding: 0 30px 25px;
    color: #2C60F5;
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
  }

  .icon {
    width: 13px;
    height: 23px;
  }

  
}
