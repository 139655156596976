.wrapper {
  padding: 83px 137px 100px;
  background: linear-gradient(180deg, #FFFFFF 0%, #F9F9F9 100%);
}

.title {
  margin-bottom: 62px;
  font-weight: 700;
  font-size: 38px;
  line-height: 60px;
  color: #7A7A7A;
  white-space: pre-wrap;
}


.card {
  position: relative;
  width: 360px;
  height: 463px;
  background: #FFFFFF;
  flex-direction: column;
  box-shadow: 0px 2px 6px 0px #0000002E;
  cursor: pointer;
}

.top {
  position: absolute;
  top: 4px;
  right: 4px;
  padding: 0 10px;
  display: flex;
  align-items: center;
  background: #2C60F5;
  color: #FFFFFF;
 
}

.img1 {
  width: 360px;
  height: 258px;
}

.content {
  padding: 20px 40px;
}


.name {
  font-weight: 700;
  font-size: 15px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.4);
  overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
  margin-bottom: 10px;
}
.type {
  font-weight: 500;
  font-size: 28px;
  line-height: 32px;
  color: #2C60F5;
  margin-bottom: 5px;
}
.desc {
  font-weight: 500;
  font-size: 17px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.6);
  text-overflow: -o-ellipsis-lastline;  
  overflow: hidden;  
  text-overflow: ellipsis;  
  display: -webkit-box;  
  -webkit-line-clamp: 2;  
  -webkit-box-orient: vertical;  
}



@media screen and (max-width: 750px) {
  .wrapper {
    padding: 80px 0 123px;
    background: linear-gradient(180deg, #FFFFFF 0%, #F9F9F9 100%);
  }

  .title {
    padding: 0 48px 0;
  }


  .card {
    position: relative;
    width: 594px;
    height: 763px;
    flex: 0 0 594px;
    padding: 96px 0 0;
    box-shadow: 0px 2px 6px 0px #0000002E;
  }

  .img1 {
    width: 594px;
    height: 426px;
  }

  
  
}
