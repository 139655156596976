


.divider {
  width: 177px;
  height: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  margin: 6px 0 11px;
}

.footer1 {
  display: none;
  background: #F9F9F9;
  flex-direction: column;
}

.navIcon1 {
  width: 41px;
  height: 36px;
  color: #888888;
  
}

.phoneNumber1 {
  font-weight: 500;
  font-size: 36px;
  line-height: 39px;
  color: #111111;
  white-space: nowrap;
  margin-bottom: 18px;
}
.arrow1 {

}
.content1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  padding: 102px 0 22px;
  border-bottom: 1px solid #D6D6D6;
}

.button1 {
  background: #FFFFFF;
  
  border: 1px solid #D6D6D6;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.05);
  border-radius: 15px;
  /* width: 356px; */
  height: 90px; 
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  font-weight: 700;
  font-size: 36px;
  line-height: 39px;
  white-space: nowrap;
  color: #888888;
  padding: 25px 40px 25px 23px;
  margin-bottom: 16px;
}
.phoneNumber {
  font-weight: 500;
  font-size: 36px;
  line-height: 39px;
  color: #111111;
}
.phone1 {
  width: 61px;
  height: 61px;
}

.items1 {
  padding: 29px 48px 35px;
  border-bottom: 1px solid #D6D6D6;

}

.item1 {

}

.plus {
  width: 48px;
  height: 48px;
}

.subItem {
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding-bottom: 30px;
  margin-bottom: 20px;
  border-bottom: 1px solid #D6D6D6;
}

.item1:last-of-type  .subItem {
  border: none;
  margin-bottom: 0px;
  padding-bottom: 0px;
}
.title1 {
  font-weight: 400;
  font-size: 32px;
  line-height: 39px;
  margin-bottom: 36px;
  color: #888888;
  display: flex;
  align-items: center;
}
.text10 {
  font-weight: 400;
  font-size: 32px;
  text-decoration-line: underline;
  color: #111111;
}

.link {
  display: flex;
  align-items: center;
  color: #7A7A7A;
}
.links1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 0;
  gap: 14px;
  font-weight: 400;
  font-size: 24px;
  line-height: 30px;
  color: #7A7A7A;
}

.copyright1 {
  font-weight: 400;
  font-size: 22px;
  line-height: 38px;
  color: #7A7A7A;
  display: flex;
  flex-direction: column;
  align-items: center;
}



@media screen and (max-width: 750px) {
  .footer {
    display: none;
  }

  .footer1 {
    display: flex;
  }
}


