
.wrapper {
  display: flex;
  flex-direction: column;
}
.header {
  width: 100%;
  height: 450px;
  background-repeat: no-repeat;
  background-position: center;

  padding: 150px 200px;
  /* background-size:  450px; */

}

.title1 {
  font-weight: 700;
font-size: 64px;
color: #000000;
margin-bottom: 30px;
}
.title2 {
  font-weight: 500;
  font-size: 24px;
  line-height: 30px;
  color: #7A7A7A;
  width: 598px;
}
.center {
  height: 80px;
  background: rgba(255, 255, 255, 0.95);
  padding: 0 136px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.catalog {
  display: flex;
  align-items: center;
  gap: 56px;
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
  color: #111111;
}
.item {
  
}
.active {
  background: rgb(122, 122, 122, 0.1);
  border-radius: 83px;
  height: 42px;
  padding: 6px 34px;
  color: #7A7A7A;
}
.content {
  padding:  15px 0 74px;
}

.content img {
  object-fit: contain;
  border: none;

}
.left {

}

