


.wrapper {
  width: 800px;
  padding: 74px 0;
  margin: 0 auto;
  line-height: 200px;
}
.title {
  font-weight: 700;
  font-size: 48px;
  line-height: 52px;
}
.divder {
  border-bottom: 1px solid #D6D6D6;
  height: 0;
  width:  100%;
  margin: 24px 0;
}
.desc {
  font-weight: 400;
  font-size: 17px;
  line-height: 25px;
  color: #333333;
  margin-bottom: 24px;
}
.image {
  width: 100%;
  height: 371px;
  object-fit: cover;
  margin-bottom: 20px;
}
.content p {
  
}

@media screen and (max-width: 750px) {
  .wrapper {
    width: 100%;
    padding: 77px 48px;
    display: flex;
    flex-direction: column;
  }
  .title {
    font-weight: 700;
    font-size: 48px;
    line-height: 60px;
    margin-bottom: 33px;
  }
  .divder {
    display: none;
  }
  .desc {
    font-weight: 400;
    font-size: 32px;
    line-height: 50px;
    margin-bottom: 24px;
    color: #888888;
    margin-bottom: 33px;
    
  }
  .image {
    width: 100%;
    height: 367px;
    margin-bottom: 33px;
  }
  .content {
  
  }
}
