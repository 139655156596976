
.card {
  display: flex;
  flex-direction: column;
  margin-bottom: 98px;
}
.title {
  font-weight: 700;
  font-size: 32px;
  line-height: 60px;
  margin-bottom: 25px;
}
.card1 {
  display: grid;
  grid-template-columns: 520px 1fr;
  grid-column-gap: 58px;

}
.cover {
  width: 100%;
  height: 100%;
  background-size: contain;
  background-repeat:   no-repeat;
  background-position: center center;
}

.left {
  border: 1px solid #D6D6D6;
  border-radius: 12px; 
  height: 400px;
  width: 100%;
  padding: 20px; 
}
.image {
  width: 100%;
  object-fit: cover;
}
.right {
  display: grid;
  grid-template-rows: 60px 1fr 58px;
  grid-row-gap: 25px;
}
.items {
  height: 60px;
  border-bottom: 1px solid #D6D6D6;
  display: flex;
  align-items: center;
  gap: 39px;
}
.item {
  width: 82px;
  cursor: pointer;
  white-space: nowrap;
  font-size: 20px;
  line-height: 60px;

}
.text {
  font-weight: 400 !important;
  font-size: 20px !important;
  line-height: 150% !important;
  color: #7A7A7A !important;
}

.text p {
  font-weight: 400 !important;
  font-size: 20px !important;
  line-height: 150% !important;
  color: #7A7A7A !important;
  letter-spacing: 0px;
  text-indent: 0em;
}

.text li {
  font-weight: 400 !important;
  font-size: 20px !important;
  line-height: 150% !important;
  color: #7A7A7A !important;
  
}

.button {
  width: 154px;
  height: 58px;
  color: #2C60F5;
  cursor: pointer;
}



@media screen and (max-width: 750px) {

  .card {
    display: flex;
    flex-direction: column;
    margin-bottom: 98px;
  }
  .title {
    font-weight: 700;
    font-size: 32px;
    line-height: 60px;
    margin-bottom: 50px;
  }
  .card1 {
    display: flex;
    flex-direction: column;
  }
  .left {
    width: 652px;
    height: 500px;
    border: 1px solid #D6D6D6;
    border-radius: 12px; 
    /* padding: 16px 76px 58px;  */
    margin-bottom: 50px;
  }
  .image {
    width: 100%;
    object-fit: cover;
  }
  .right {
    display: grid;
    grid-template-rows: 60px 1fr 58px;
    grid-row-gap: 25px;
  }
  .items {
    height: 60px;
    border-bottom: 1px solid #D6D6D6;
    display: flex;
    align-items: center;
    gap: 39px;
  }
  .item {
    width: 82px;
    cursor: pointer;
    font-weight: 500;
    font-size: 32px;
    line-height: 60px;

  }
  .text {
    font-weight: 400 !important;
    font-size: 32px !important;
    line-height: 56px !important;
    color: #7A7A7A !important;
  }

  .text li {
    font-weight: 400 !important;
    font-size: 32px !important;
    line-height: 56px !important;
   
    color: #7A7A7A !important;
  }
  

  .button {
    width: 230px;
    height: 87px;
    color: #2C60F5;
  }
  
}