.wrapper {
  background-color: #ffffff;
  padding: 10px 0px 00px 0px;
  display: flex;
  flex-direction: column;
  margin-bottom: -4px;
  position: relative;
  display:  none;
}


.wrapper video {
  width: 110%;
  transform: translate(-100px, 0px);

}

.title {
  font-weight: 700;
  font-size: 38px;
  line-height: 60px;
  color: rgba(255, 255, 255, 0.7);
  margin: 0px 100px -100px 170px;
  z-index: 2;
}

.image {
  width: 724px;
  height: 520px;
  align-self: center;
}

.mask{
  height: 100px;
  background: linear-gradient(#2c60f500, #2C60F5);
  position: absolute;
  bottom: 0px;
  width: 100vw;
  z-index: 1;
}

@media screen and (max-width: 750px) {
  .wrapper {
    background-color: #ffffff;
    padding: 10px 0px 00px 0px;
    display: flex;
    flex-direction: column;
    height: 140%;
    position: relative;
    display:  none;

  }

  .wrapper video {
    width: 162%;
    transform: translate(-230px, 0px);
  }
  
  .title {
    font-weight: 700;
    font-size: 48px;
    line-height: 60px;
    color: rgba(255, 255, 255, 0.7);
    margin: 0px 100px -130px 50px;
    z-index: 2;
  }
  
  .image {
    width: 654px;
    height: 470px;
    align-self: center;
  }

  .cover {
    position: absolute;
    height: 105%;
    transform: translate(-210px, -30px);
  }
  
}
