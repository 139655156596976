.footer {
  padding: 36px 136px 0;
  background: #F9F9F9;
}

.navs {
  display: flex;
  align-items: center;
  margin-bottom: 44px;
  gap: 16px;
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
  color: #888888;
}

.arrow {
  width: 9px;
  height: 15px;
  color: #CFCFCF;
}
.navIcon {
  width: 34px;
  height: 30px;
  cursor: pointer;
}
.arrowIcon {

}
.arrowIcon:last-child {
  display: none;
 
}
.content {
  display: grid;
  grid-template-columns: 250px 1fr 1fr 1fr 1fr;
  grid-column-gap: 67px;
  justify-content: center;
}
.contract {
  
}

.title {
  font-weight: 700;
  font-size: 16px;
  line-height: 30px;
}

.text1 {
  font-weight: 500;
  font-size: 24px;
  line-height: 26px;
  color: #111111;
  margin-bottom: 26px;
}
.text2 {
  font-weight: 700;
  font-size: 32px;
  line-height: 26px;
  margin-bottom: 18px;
}
.text3 {
  font-weight: 500;
  font-size: 24px;
  line-height: 45px;
  color: #888888;
}
.items {
  display: flex;
}
.text4 {
  font-weight: 500;
  font-size: 14px;
  line-height: 30px;
  color: #888888;
  margin-bottom: 5px;
  cursor: pointer;
}
.text4:hover {
  color: #888888;
}

.qrcode {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  color: rgba(0, 0, 0, 0.5)
}

.qrcode1 {
  width: 130px;
  height: 127px;
  margin-bottom: 10px;
}
.links {
  height: 59px;
  border-top: 1px solid #D6D6D6;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 400;
  font-size: 15px;
  line-height: 30px;
  color: #7A7A7A;
  margin-top: 68px;
  cursor: pointer;
}

.link {
  display: flex;
  align-items: center;
}

.divider {
  width: 177px;
  height: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  margin: 6px 0 11px;
}


.plus {
  width: 48px;
  height: 48px;
}


@media screen and (max-width: 750px) {
  .footer {
    display: none;
  }

  .footer1 {
    display: flex ;
  }
}

