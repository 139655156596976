.navs {
  width: 100vw;
  position: fixed;
  top: 98px;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  padding: 0 48px;
  border-top: 1px solid #D6D6D6;
  z-index: 1000;
  
}

.items {
  overflow-y: auto;
}

.items::-webkit-scrollbar {
  display: none;
}

.card {
  border-bottom:  1px solid #D6D6D6;
}

.item {
  height: 100px;
 
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 32px;
  line-height: 21px;
  color: #000000;
  position: relative;
  transition: all 0.5s;
}

.active {
  background: linear-gradient(88.4deg, #2C60F5 -14.4%, #E60013 121.4%);
  color: #FFFFFF;
}

.content1 {
  padding: 40px 0 40px 112px;
}
.content {
  /* height: 0;
  padding: 0; */
  /* max-height: 0; */
  /* height: 0; */
  transform: scaleY(0);
  
 
  transition: all 0.3s;
  height: 0;
  /* box-sizing: border-box; */
  overflow: hidden;
  /* display: none; */
  box-sizing: content-box;
}

.contentActive {
  height: auto;
  
  transform: scaleY(1);
  transform-origin: 0;
  /* max-height: 1000px; */
  display: flex;
  flex-direction: column;
}

.nav {
  font-weight: 350;
  font-size: 32px;
  line-height: 26px;
  color: #333333;
  margin-bottom: 40px;
}

.nav:last-of-type {
  margin-bottom: 0;
}

.arrow {
  height: 22px;
  width: 13px;
  transform:  translateY(-50%) rotate(90deg);
  position: absolute;
  left: 65px;
  top: 50%;
}